import React from 'react';
import PropTypes from 'prop-types';
import MGrid from '@material-ui/core/Grid';

class GridItem extends React.PureComponent {
  render() {
    return (
      <MGrid
        className={`grid-item ${this.props.className}`}
        item
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        xl={this.props.xl}
      >
        {this.props.children}
      </MGrid>
    );
  }
}

GridItem.defaultProps = {
  className: '',
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  xs: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

export default GridItem;
