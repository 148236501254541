import parse from 'html-react-parser';

function attClick(e, name, type = 'navigation') {
  if (e && name) {
    window.ATTag.click.send({
      elem: e.target,
      name,
      level2: 'clickLvl2',
      type,
    });
  }
}

export default function Parser(s) {
  const res = parse(s);
  if (Array.isArray(res)) {
    return res.map(item => {
      if (item.props && item.type === 'a' && item.props['data-name']) {
        const type = item.props['data-type'] || 'navigation';
        const copy = {
          ...item,
          props: {
            ...item.props,
            onClick: e => attClick(e, item.props['data-name'], type),
          },
        };
        return copy;
      }
      return item;
    });
  }
  return res;
}
